import React from "react";
import { Container, Row, Col } from "reactstrap";
import PowerfulCard from "./PowerfulCard.jsx";

const createCards = use => {
  let features = [];
  let cards = [
    {
      class: "mt-6 mt-6 mx-lg-auto",
      icon: "portfolio",
      title: "Services",
      animation: { delay: 0 },
      description:
        "From dog walking services to vets, everything under the same roof"
    },

    {
      class: "mx-lg-auto",
      icon: "wine",
      title: "Establishments",
      animation: { delay: 800 },
      description: "Locally sourced and community confirmed Pubs, Bars, Hotels"
    },

    {
      class: "mr-lg-auto",
      icon: "map-2",
      title: "Events",
      animation: { delay: 500 },
      description: "Full up-to-date information on local events and concerts"
    },

    {
      class: "mr-lg-auto",
      icon: "like",
      title: "Pawsome Charities",
      animation: { delay: 500 },
      description: "Help dogs get the love and care they deserve"
    }
  ];

  cards.forEach((el, i) => {
    if (i % 2 === use) {
      features.push(<PowerfulCard {...el} key={i} />);
    }
  });

  return features;
};

const PowerfulFeatures = () => {
  return (
    <section className="section alter3-features">
      <div className="shapes-container">
        <div className="shape shape-ring shape-ring-1">
          <div className="animation animation--rotating" />
        </div>
        <div className="shape shape-ring shape-ring-2">
          <div className="animation animation--rotating" />
        </div>
        <div className="shape shape-circle animation--clockwise">
          <div />
        </div>

        <div className="shape background-shape-main" />
      </div>

      <Container>
        <Row>
          <Col lg="5" className="order-lg-last">
            <div className="section-heading">
              <p className="px-2 text-alternate text-uppercase bold">
                Unlock the power of the community.
              </p>
              <h2 className="heading-line">
                Everything your paw friend needs.
              </h2>

              <p className="lead text-muted my-4 semi-bold">
                Because you and your paw friend deserve the best!
              </p>

              <p>
                From dog friendly establishments to dog service information at
                your fingertips.{" "}
              </p>
            </div>
          </Col>

          <Col lg="7" className="pr-lg-6">
            <Row>
              <Col lg="6" className="rotated-cards">
                {createCards(0)}
              </Col>
              <Col lg="6" className="rotated-cards">
                {createCards(1)}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PowerfulFeatures;
