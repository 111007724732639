import React from "react";
import { Container, Row, Col, UncontrolledCollapse } from "reactstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Fade from "react-reveal/Fade";

const questions = [
  {
    question: "Is the platform open to register?",
    answer:
      "We are still under development, our goal is to release the first version of the platform under closed-beta in Cambridge UK followed by pilot in the UK which we will make available for everyone."
  },
  {
    question: "How can I get in touch with you?",
    answer:
      "Initially, while we are building the service, we would suggest emailing us at info@dogsallowed.io or by using the Contact us form at our website. For the future we will aim to incorporate everything in the platform."
  },
  {
    question: "Can I suggest a new business?",
    answer:
      "Absolutely! We believe the community should be in the centre of what we do. That is why all the features we have planned to build share a simple philosophy of information enablement."
  }
];

const Faqs = () => {
  return (
    <section className="section faqs-dd bg-light edge top-left">
      <div className="shapes-container">
        <div className="absolute icon">
          <Fade top left>
            <FontAwesomeIcon icon={["fas", "question"]} />
          </Fade>
        </div>
      </div>

      <Container>
        <div className="section-heading text-center">
          <h2 className="heading-line">Frequently Asked Questions</h2>
          <p className="lead">Want to know more about us?</p>
        </div>

        <Row>
          <Col lg="8" className="mx-lg-auto">
            {questions.map((faq, i) => (
              <Fade bottom key={i}>
                <div className="card shadow-box shadow-hover mb-3" key={i}>
                  <div className="card-header py-3">
                    <Link
                      to="#"
                      className="card-title collapsed"
                      id={`toggler-${i}`}
                    >
                      {faq.question}
                    </Link>
                  </div>

                  <UncontrolledCollapse toggler={`#toggler-${i}`}>
                    <div className="card-body">{faq.answer}</div>
                  </UncontrolledCollapse>
                </div>
              </Fade>
            ))}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Faqs;
