import React from "react";
import { Container, Row, Col } from "reactstrap";
import Fade from "react-reveal/Fade";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Proposal = () => {
  return (
    <section className="section our-proposal">
      <div className="shapes-container">
        <Fade bottom delay={300}>
          <div className="shape shape-circle pattern-dots" />
        </Fade>

        <Fade bottom>
          <div className="shape shape-circle shape-circle-fill" />
        </Fade>

        <div className="pattern pattern-dots" />
      </div>

      <Container className="bring-to-front">
        <Row className="align-items-center">
          <Col md="6" className="order-md-last">
            <figure
              className="mockup"
              style={{ width: "920px", maxWidth: "100%" }}
            >
              <img
                src={require("assets/img/screens/wmockup/1.png")}
                className="img-responsive"
                alt="..."
              />
            </figure>
          </Col>

          <Col md="6">
            <h2 className="heading-line">What are you waiting for?</h2>
            <p className="lead text-muted">
              Sign-up today and join the Dogs Allowed community!
            </p>
            <nav className="nav my-5">
              <a
                href="https://share.hsforms.com/1TQFtLkd_TQW_dmhWIXHRWw3sazg"
                className="btn btn-rounded btn-alternate mr-2 mr-md-5"
              >
                Register interest{" "}
                <FontAwesomeIcon
                  icon={["fas", "long-arrow-alt-right"]}
                  className="ml-2"
                />
              </a>
            </nav>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Proposal;
