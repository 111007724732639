import React from "react";
import { Container, Row, Col, Nav, NavItem } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const renderPartners = () => {
  let partners = [];

  for (let i = 1; i <= 6; ++i) {
    partners.push(
      <NavItem className="mr-3 py-2 op-5" key={i}>
        <img
          src={require(`assets/img/logos/${i}.png`)}
          className="icon"
          alt=""
        />
      </NavItem>
    );
  }

  return partners;
};

const Partners = () => {
  return (
    <section className="section alter3-partners bg-contrast edge top-left">
      <Container className="bring-to-front pb-md-9">
        <Row>
          <Col md="6">
            <div className="section-heading">
              <FontAwesomeIcon
                icon={["fas", "map-marked-alt"]}
                className="fa-3x accent mb-3"
              />

              <h3 className="text-alternate heading-line">
                We are mapping all pawsome businesses in Cambridge.
              </h3>

              <p className="lead">
                We are on a mission to find the best dog-friendly spots in town
                and stop leaving our dogs home alone. We have taken our dogs to
                pubs, restaurants, coffee shops, events, and guest houses. Their
                tails have wagged and their bellies have been rubbed -{" "}
                <b>we refuse to leave them behind.</b>
              </p>
            </div>

            <Nav>{renderPartners()}</Nav>
            <nav className="nav mt-3 mt-lg-0 ml-lg-auto">
              <a
                href="https://share.hsforms.com/1QNAsTjgpQrGkUr3x-zRDaw3sazg"
                className="btn btn-outline-primary bold"
              >
                Ready for your next four-legged adventure?{" "}
                <FontAwesomeIcon
                  icon={["fas", "long-arrow-alt-right"]}
                  className="icon"
                />
              </a>
            </nav>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Partners;
