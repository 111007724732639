import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const renderFeatures = () => {
  let features = [];
  const items = [
    {
      name: "Services",
      icon: "bone",
      description: "From dog walking to vets, everything under the same roof"
    },
    {
      name: "Establishments",
      icon: "hotel",
      description: "Locally sourced and community confirmed Pubs, Bars, Hotels"
    },
    {
      name: "Events",
      icon: "map-marked-alt",
      description: "Full up-to-date information on local events and concerts"
    },
    {
      name: "Charities",
      icon: "heart",
      description: "Help dogs get the love and care they deserve"
    }
  ];

  items.forEach((f, i) => {
    features.push(
      <li className="list-item" key={i}>
        <div className="media align-items-center">
          <div className="icon-shape mr-3">
            <div className="shape shape-pipes" />
            <FontAwesomeIcon
              icon={["fas", f.icon]}
              className="icon fa-3x text-alternate"
            />
          </div>

          <div className="media-body">
            <h5 className="bold">{f.name}</h5>
            <p className="my-0">{f.description}</p>
          </div>
        </div>
      </li>
    );
  });

  return features;
};

const WhyUs = () => {
  return (
    <section className="section why-choose-us">
      <div className="shapes-container">
        <div className="pattern pattern-dots" />
      </div>

      <div className="container pb-8 bring-to-front">
        <div className="section-heading text-center">
          <h2 className="heading-line">Why you should use Dogs Allowed?</h2>
          <p className="text-muted lead mx-auto"></p>
        </div>

        <div className="row gap-y">
          <div className="col-md-6">
            <h4 className="bold text-alternate">
              No more guilt for leaving your friend home
            </h4>
            <p className="text-muted lead mb-5">
              Unsure if the place you are going to is dog friendly or looking
              for the best place to pamper your pooch? Simply switch on our
              application and check dog friendly places near your live location.
            </p>

            <ul className="list-unstyled why-icon-list">{renderFeatures()}</ul>

            <hr className="my-5" />
            <div className="text-center text-md-left">
              <nav className="btn btn-primary">
                <a
                  href="https://share.hsforms.com/1QNAsTjgpQrGkUr3x-zRDaw3sazg"
                  className="text-contrast"
                >
                  Register interest{" "}
                </a>
              </nav>
            </div>
          </div>

          <div className="col-md-6">
            <div className="rotated-mockups device-twin">
              <div className="browser absolute shadow-lg">
                <img
                  src={require("assets/img/screens/tablet/1.png")}
                  alt="..."
                />
              </div>

              <div className="front iphone light">
                <div className="screen">
                  <img
                    src={require("assets/img/screens/app/1.png")}
                    alt="..."
                  />
                </div>

                <div className="notch" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
