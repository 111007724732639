import React from "react";
import { Container, Row, Col } from "reactstrap";

const renderFeatures = () => {
  let features = [];
  const elements = [
    {
      icon: "pe-7s-map-2",
      title: "Claim your business",
      description: "Register to get started and claim your business profile."
    },
    {
      icon: "pe-7s-graph1",
      title: "Generate new customers",
      description:
        "Our platform is specifically designed for the dog community, giving you instant access to advertise to a very niche specific and hot audience."
    },
    {
      icon: "pe-7s-comment",
      title: "Start the conversation",
      description:
        "From creating events to answering comments, we give you the freedom to engage with the Dogs Allowed community."
    }
  ];

  elements.forEach((element, i) => {
    features.push(
      <Col md="4" className="py-4 rounded shadow-hover">
        <i className={`pe ${element.icon} pe-3x mb-3 text-primary`} />
        <h5 className="bold">{element.title}</h5>
        <p>{element.description}</p>
      </Col>
    );
  });

  return features;
};

const Features = () => {
  return (
    <section id="features" className="section features">
      <Container>
        <div className="section-heading text-center">
          <h2 className="heading-line">Stay in control</h2>
          <p className="lead text-muted">
            We are creating business friendly features that enables you to
            attact new customers and gives you the power to connect.
          </p>
        </div>

        <Row className="gap-y text-center text-md-left">{renderFeatures()}</Row>
      </Container>
    </section>
  );
};

export default Features;
